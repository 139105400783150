const LoginMessages = {
  selectAccountType: 'Select your account type.',
  trialDescription: 'Explore Skyflow in a trial environment.',
  productionDescription: 'Work with Skyflow in a live, active environment.',
  continue: 'Continue',
  dontKnowAccountType: 'Don’t know your account type?',
  dontHaveAccount: 'Don’t have a Skyflow account?',
  enterAccountName: 'Enter your account name',
  accountName: 'Account Name',
  required: '*',
  trialPrefix: 'try.skyflow.dev/t/',
  goBack: 'Go Back',
  dontKnowAccountName: 'Don’t know your account name?',
  prodSuffix: '.skyflow.dev',
  enterValidAccountName: 'Enter a valid account name',
  try: 'try',
  signInTitle: 'Sign in to your Skyflow account',
  sandboxDescription: 'Integrate with Skyflow in a development environment.',
  sandboxSuffix: '.skyflow-preview.com'
};

export default LoginMessages;
