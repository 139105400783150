/* eslint-disable max-lines-per-function */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '../Typography';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  styled,
  InputBase,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import globalMessages from '../../../utils/messages/en/globalMessages';
import logo from '../../../../static/images/logo.svg';
import theme, { BREAKPOINTS, COLORS } from '../../../utils/theme';
import LinkWrapper from '../Link';
import LoginMessages from '../../../utils/messages/en/LoginMessages';
import {externalLinks} from '../../../utils/constants';
import { ACCOUNT_DETAILS, graphQLClient, sandboxClient } from './hook';
import warning from '../../../../static/images/warning.svg';

const TrialInputBase = styled(InputBase)(({ theme }) => ({
  width: 'calc(100% - 153px)',
}));
const ProdInputBase = styled(InputBase)(({ theme }) => ({
  width: 'calc(100% - 120px)',
  '& .MuiInputBase-input': {
    paddingLeft: '16px',
    height: '36px',
    transition: theme.transitions.create('width'),
    width: '100%',
    border: '1px solid' + COLORS.OUTLINE_COLOR,
    borderRadius: '4px 0px 0px 4px',
  },
}));

const styles = {
  outerBox: {
    maxWidth: 534,
    minWidth: 470,
    minHeight: 552,
    maxHeight: 724,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    p: 8,
    margin: 'auto',
    [BREAKPOINTS.MEDIA_MAX_550]: {
      minWidth: 'calc(100% - 33px)',
      pl: 4,
      pr: '17px',
    },
  },
  innerBox: {
    maxWidth: 534,
    minWidth: 470,
    minHeight: 380,
    maxHeight: 444,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    p: 8,
    margin: 'auto',
    [BREAKPOINTS.MEDIA_MAX_550]: {
      minWidth: 'calc(100% - 33px)',
      pl: 4,
      pr: '17px',
    },
  },
  boxStyle: {
    border: '1px solid',
    padding: '16px',
    borderRadius: 1,
  },
  cardStyle: {
    border: '1px solid' + theme.palette.grey[300],
    padding: '16px',
    marginTop: '12px',
    borderRadius: 1,
    width: '100%',
  },
  cardTitleStyle: {
    color: COLORS.SECONDARY_MIDNIGHT,
    fontWeight: 700,
  },
  radioButton: {
    color: COLORS.OUTLINE_COLOR,
    '&.Mui-checked': {
      color: COLORS.PRIMARY_ACTIONS,
    },
  },
  descriptionStyle: {
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    pl: 8,
  },
  divider: {
    border: '1px solid' + theme.palette.grey[300],
    [BREAKPOINTS.MEDIA_MAX_550]: {
      minWidth: 'calc(100% - 33px)',
    },
  },
  title: {
    fontWeight: 700,
    paddingBottom: 3,
    color: COLORS.SECONDARY_MIDNIGHT,
  },
  sandboxsuffixStyle: {
    width: '179px',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[100],
    border: '1px solid' + COLORS.OUTLINE_COLOR,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0px 4px 4px 0px',
  },
  suffixStyle: {
    width: '120px',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[100],
    border: '1px solid' + COLORS.OUTLINE_COLOR,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0px 4px 4px 0px',
  },
  prefixStyle: {
    width: '153px',
    height: '46px',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[100],
    border: '1px solid' + COLORS.OUTLINE_COLOR,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px 0px 0px 4px',
  },
  logoBox: {
    width: '121px',
    height: '36px',
    paddingBottom: '48px',
    margin: 'auto',
  },
  imagestyle: {
    width: '121px',
    height: '36px',
  },
  subHeader: {
    paddingBottom: 3,
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
  },
  subHeaderBox: {
    paddingBottom: 12,
  },
  formGroup: {
    width: '100%',
  },
  formControl: {
    width: 'calc(100% - 32px)',
  },
  continueButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    pb: '24px',
    [BREAKPOINTS.MEDIA_MAX_550]: {
      display: 'block',
    },
  },
  continueBox: {
    '&.Mui-disabled ': {
      color: theme.palette.common.white,
    },
    [BREAKPOINTS.MEDIA_MAX_550]: {
      width: '100%',
    },
  },
  secondaryText: {
    pt: 8,
    display: 'flex',
    justifyContent: 'center',
  },
  teritiaryText: {
    pt: 5,
    display: 'flex',
    justifyContent: 'center',
  },
  headerBox: {
    pb: 12
  },
  mainHeader: {
    paddingBottom: '28px',
  },
  inputLabel: {
    display: 'flex',
    pb: '5px',
  },
  ashtrixStyle: {
    pl: 2,
    color: COLORS.TEXT_DELETE,
  },
  inputBox: {
    display: 'flex',
    borderCollapse: 'separate',
    maxHeight: '48px',
  },
  goButtonStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    gap: 3,
    pb: 6,
    [BREAKPOINTS.MEDIA_MAX_550]: {
      justifyContent: 'space-between',
    },
  },
  textLink: {
    pt: 8,
    pb: 2,
    display: 'flex',
    justifyContent: 'center',
  },
  disabledButton: {
    '&.Mui-disabled ': {
      color: theme.palette.common.white,
    },
  },
  textFieldBoxStyle: {
    '&.MuiTextField-root': {
      width: '317px',  
    },
    '& .MuiInputBase-root': {
      paddingLeft: '16px',
      height: '50px',
      transition: theme.transitions.create('width'),
      border: '1px solid' + COLORS.OUTLINE_COLOR,
      borderRadius: '0px 4px 4px 0px',
    },
  },
  errorFieldBoxStyle: {
    '&.MuiTextField-root': {
      width: '317px',  
    },
    '& .MuiInputBase-root': {
      paddingLeft: '16px',
      height: '50px',
      transition: theme.transitions.create('width'),
      border: '1px solid' + COLORS.TEXT_DELETE,
      borderRadius: '0px 4px 4px 0px',
    },
  },
  inputBoxStyle: {
    '& .MuiInputBase-input': {
      paddingLeft: '16px',
      height: '36px',
      transition: theme.transitions.create('width'),
      width: '100%',
      border: '1px solid' + COLORS.OUTLINE_COLOR,
      borderRadius: '0px 4px 4px 0px',
    },
  },
  errorInputBoxStyle: {
    '& .MuiInputBase-input': {
      paddingLeft: '16px',
      height: '36px',
      transition: theme.transitions.create('width'),
      width: '100%',
      border: '1px solid' + COLORS.TEXT_DELETE,
      borderRadius: '0px 4px 4px 0px',
    },
  },
  errorBox: {
    paddingTop: '8px',
    display: 'flex',
    gap: '8px',
  },
  loadingCircle: {
    color: theme.palette.primary.main,
    marginRight: '2px',
  },
};

interface AccountDetails {
  OktaClientDetails: any;
  ValidateAccounts: any;
}

const redirectToProdAccount = (accountName, route) => {
  let domain = LoginMessages.prodSuffix;
  if (typeof window !== undefined) {
    const expectedDomain = window.location.hostname;
    const result = expectedDomain.split('.');
    if (result.length >= 3) {
      domain = '.skyflow.' + result[2];
    }
  }
  const href = ' https://' + accountName + domain + '/?docs=' + route;
  window.open(href, '_self');
};
const getProdSuffix = () => {
  if (typeof window !== undefined) {
    let domain = window.location.hostname;
    const result = domain.split('.');
    if (result.length >= 3) {
      domain = '.skyflow.' + result[2];
      return domain;
    }
  }
  return LoginMessages.prodSuffix;
};
const getTrialPrefix = () => {
  if (typeof window !== undefined) {
    let domain = window.location.hostname;
    const result = domain.split('.');
    if (result.length >= 3) {
      domain = 'try.skyflow.' + result[2] + '/t/';
      return domain;
    }
  }
  return LoginMessages.trialPrefix;
};

const getSandboxSuffix = () => {
  if (typeof window !== undefined) {
    let domain = window.location.hostname;
    const result = domain.split('.');
    if (result.length >= 3) {
      domain = '.skyflow-preview.' + result[2];
      return domain;
    }
  }
  return LoginMessages.sandboxSuffix;
};

const redirectToTrialAccount = (trialAccountName, route) => {
  let domain = LoginMessages.trialPrefix;
  if (typeof window !== undefined) {
    const expectedDomain = window.location.hostname;
    const result = expectedDomain.split('.');
    if (result.length >= 3) {
      domain = 'try.skyflow.' + result[2] + '/t/';
    }
  }
  const href = 'https://' + domain + trialAccountName + '/?docs=' + route;
  window.open(href, '_self');
};
const redirectToSandboxAccount = (accountName, route) => {
  let domain = LoginMessages.sandboxSuffix;
  if (typeof window !== 'undefined') {
    const currentDomain = window.location.hostname;
    const result = currentDomain.split('.');
    if (result.length >= 3) {
      domain = '.skyflow-preview.' + result[2];
    }
  }
  const href = ' https://' + accountName + domain + '/?docs=' + route;
  window.open(href, '_self');
};
const LoginForm = ({ route }) => {

  const [AccountData, setAccountData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [ActiveStep, setActiveStep] = React.useState(0);
  const [prodAccountName, setProdAccountName] = React.useState('');
  const [trialAccountName, setTrialAccountName] = React.useState('');
  const [sandboxAccountName, setSandboxAccountName] = React.useState('');
  const [isValid, setIsValid] = React.useState(true);
  const [inputAdornment, setAdornment] = React.useState(<InputAdornment position="start" style={{paddingRight:'105px'}}>-try</InputAdornment>);
  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    setIsValid(true);
  }, [ActiveStep]);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getAccount = async (variables) => {
    await graphQLClient.request(ACCOUNT_DETAILS, variables)
    .then((data) => {
      setAccountData(data);
    })
    .catch((error) => {
      setIsValid(false);
      setLoading(false);
      setTrialAccountName('');
      setProdAccountName('');
      setAdornment(<InputAdornment position="start" style={{paddingRight:'105px'}}>-try</InputAdornment>);
    });
  };

  const getSandboxAccount = async (variables) => {
    await sandboxClient.request(ACCOUNT_DETAILS, variables)
    .then((data) => {
      setAccountData(data);
    })
    .catch((error) => {
      setIsValid(false);
      setLoading(false);
      setSandboxAccountName('');
    });
  };

  const handleTrialAccountRedirection = async () => {
    setLoading(true);
    getAccount({
        accountName: LoginMessages.try,
        tenantName: trialAccountName,
    });
  };
  const handleProductionAccountRedirection = async () => {
    setLoading(true);
    getAccount({
        accountName: prodAccountName,
        tenantName: prodAccountName,
    });
  };

  const handleSandboxAccountRedirection = async () => {
    setLoading(true);
    getSandboxAccount({
      accountName: sandboxAccountName,
      tenantName: sandboxAccountName,
    });
  };

  React.useEffect(() => {
    if (AccountData) {
      if (trialAccountName !== '' && selectedValue === globalMessages.Trial) {
        redirectToTrialAccount(trialAccountName, route);
      } else if (
        prodAccountName !== '' &&
        selectedValue === globalMessages.Production
      ) {
        redirectToProdAccount(prodAccountName, route);
      }
      else if (sandboxAccountName !== '' && selectedValue === globalMessages.Sandbox) {
        redirectToSandboxAccount(sandboxAccountName, route);
      }
    }
  }, [AccountData]);

  React.useEffect(() => {
    if(trialAccountName.includes('-try') && trialAccountName.length === 5 && ref.current)
    {
      ref.current.selectionEnd = ref.current.selectionStart = 1;
      ref.current.focus();
      setAdornment(<></>);
    }
    !trialAccountName && setAdornment(<InputAdornment position="start" style={{paddingRight:'105px'}}>-try</InputAdornment>);
  },[trialAccountName]);
  return (
    <Box>
      {ActiveStep === 0 && 
        <Box>
          <Box sx={styles.outerBox}>
            <Box>
              <Box sx={styles.logoBox}>
                <img src={logo} style={styles.imagestyle} />
              </Box>
              <Box sx={styles.subHeaderBox}>
              <Typography variant="h4" sx={styles.title}>
                  {LoginMessages.signInTitle}
                </Typography>
                <Typography variant="h6" sx={styles.subHeader}>
                  {LoginMessages.selectAccountType}
                </Typography>
                <Box sx={styles.formGroup}>
                  <FormControl sx={styles.formControl}>
                    <RadioGroup>
                      <Box sx={styles.cardStyle}>
                        <FormControlLabel
                          value={globalMessages.Trial}
                          control={
                            <Radio
                              checked={selectedValue === globalMessages.Trial}
                              onChange={handleChange}
                              value={globalMessages.Trial}
                              name="radio-buttons"
                              sx={styles.radioButton}
                              inputProps={{
                                'aria-label': globalMessages.Trial,
                              }}
                            />
                          }
                          label={
                            <Typography type="SMALL_LIST_HEADER">
                              {globalMessages.Trial}
                            </Typography>
                          }
                          sx={styles.cardTitleStyle}
                        />
                        <Typography
                          sx={styles.descriptionStyle}
                          type="REGULAR_TABLE_CELL"
                        >
                          {LoginMessages.trialDescription}
                        </Typography>
                      </Box>
                      <Box sx={styles.cardStyle}>
                        <FormControlLabel
                          value={globalMessages.Sandbox}
                          control={
                            <Radio
                              checked={selectedValue === globalMessages.Sandbox}
                              onChange={handleChange}
                              value={globalMessages.Sandbox}
                              name="radio-buttons"
                              sx={styles.radioButton}
                              inputProps={{
                                'aria-label': globalMessages.Sandbox,
                              }}
                            />
                          }
                          label={
                            <Typography type="SMALL_LIST_HEADER">
                              {globalMessages.Sandbox}
                            </Typography>
                          }
                          sx={styles.cardTitleStyle}
                        />
                        <Typography
                          sx={styles.descriptionStyle}
                          type="REGULAR_TABLE_CELL"
                        >
                          {LoginMessages.sandboxDescription}
                        </Typography>
                      </Box>
                      <Box sx={styles.cardStyle}>
                        <FormControlLabel
                          value={globalMessages.Production}
                          control={
                            <Radio
                              checked={
                                selectedValue === globalMessages.Production
                              }
                              onChange={handleChange}
                              value={globalMessages.Production}
                              name="radio-buttons"
                              sx={styles.radioButton}
                              inputProps={{
                                'aria-label': globalMessages.Production,
                              }}
                            />
                          }
                          label={
                            <Typography type="SMALL_LIST_HEADER">
                              {globalMessages.Production}
                            </Typography>
                          }
                          sx={styles.cardTitleStyle}
                        />
                        <Typography
                          sx={styles.descriptionStyle}
                          type="REGULAR_TABLE_CELL"
                        >
                          {LoginMessages.productionDescription}
                        </Typography>
                      </Box>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              <Box sx={styles.continueButton}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }}
                  sx={styles.continueBox}
                  disabled={selectedValue !== '' ? false : true}
                >
                  {LoginMessages.continue}
                </Button>
              </Box>
              <Box sx={styles.divider} />
              <Box sx={styles.secondaryText}>
                <LinkWrapper href={externalLinks.dontKnowAccountType}>
                  <Typography type="SMALL_SUB_HEADER">
                    {LoginMessages.dontKnowAccountType}
                  </Typography>
                </LinkWrapper>
              </Box>
              <Box sx={styles.teritiaryText}>
                <LinkWrapper href={externalLinks.dontHaveAccount}>
                  <Typography type="SMALL_SUB_HEADER">
                    {LoginMessages.dontHaveAccount}
                  </Typography>
                </LinkWrapper>
              </Box>
            </Box>
          </Box>
        </Box>
      }
      {ActiveStep === 1 && selectedValue === globalMessages.Trial && 
        <Box sx={styles.innerBox}>
          <Box sx={styles.logoBox}>
            <img src={logo} style={styles.imagestyle} />
          </Box>
          <Box sx={styles.headerBox}>
          <Typography variant="h4" sx={styles.title}>
                {LoginMessages.signInTitle}
            </Typography>
            <Typography type="MEDIUM_SUB_HEADER" sx={styles.mainHeader}>
              {LoginMessages.enterAccountName}
            </Typography>
            <Box>
              <Box sx={styles.inputLabel}>
                <Typography type="INPUT_LABEL">
                  {LoginMessages.accountName}
                </Typography>
                <Typography type="INPUT_LABEL" sx={styles.ashtrixStyle}>
                  {LoginMessages.required}
                </Typography>{' '}
              </Box>
              <Box sx={styles.inputBox}>
                <Typography type="REGULAR_TABLE_CELL" sx={styles.prefixStyle}>
                  {getTrialPrefix()}
                </Typography>
                <TextField
                  placeholder="your-account-name"
                  inputRef={ref}
                  variant="standard"
                  value={trialAccountName}
                  InputProps={{ 
                    endAdornment: inputAdornment,
                    disableUnderline: true
                  }}
                  onChange={(e) => {
                    setTrialAccountName(trialAccountName ? e.target.value.toLowerCase() : e.target.value.toLowerCase() + '-try');
                    setIsValid(true);
                  }}
                  autoFocus
                  onBlur={()=>{
                    !trialAccountName && setAdornment(<InputAdornment position="start" style={{paddingRight:'105px'}}>-try</InputAdornment>);
                  }}
                  sx={
                    !isValid ? styles.errorFieldBoxStyle : styles.textFieldBoxStyle
                  }
                />
              </Box>
              {!isValid && 
                <Box sx={styles.errorBox}>
                  <img src={warning} width="16px" height="16px"></img>
                  <Typography
                    type="INPUT_LABEL"
                    sx={{ color: COLORS.TEXT_DELETE }}
                  >
                    {LoginMessages.enterValidAccountName}
                  </Typography>
                </Box>
              }
            </Box>
          </Box>
          <Box sx={styles.goButtonStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }}
              disabled={!loading ? false : true}
            >
              {LoginMessages.goBack}
            </Button>
            <Button
              variant="contained"
              disabled={trialAccountName.length > 0 && !loading ? false : true}
              onClick={() => {
                handleTrialAccountRedirection();
              }}
              sx={styles.disabledButton}
            >
              {loading && 
                <CircularProgress size={20} sx={styles.loadingCircle} />
              }
              {LoginMessages.continue}
            </Button>
          </Box>
          <Box sx={styles.divider} />
          <Box sx={styles.textLink}>
            <LinkWrapper href={externalLinks.dontKnowAccountName}>
              <Typography type="SMALL_SUB_HEADER">
                {LoginMessages.dontKnowAccountName}
              </Typography>
            </LinkWrapper>
          </Box>
        </Box>
      }

      {ActiveStep === 1 && selectedValue === globalMessages.Sandbox && 
        <Box sx={styles.innerBox}>
          <Box sx={styles.logoBox}>
            <img src={logo} style={styles.imagestyle} />
          </Box>
          <Box sx={styles.headerBox}>
          <Typography variant="h4" sx={styles.title}>
                  {LoginMessages.signInTitle}
                </Typography>
            <Typography type="MEDIUM_SUB_HEADER" sx={styles.mainHeader}>
              {LoginMessages.enterAccountName}
            </Typography>
            <Box>
              <Box sx={styles.inputLabel}>
                <Typography type="INPUT_LABEL">
                  {LoginMessages.accountName}
                </Typography>
                <Typography type="INPUT_LABEL" sx={styles.ashtrixStyle}>
                  {LoginMessages.required}
                </Typography>{' '}
              </Box>
              <Box sx={styles.inputBox}>
                <ProdInputBase
                  placeholder="your-account-name"
                  value={sandboxAccountName}
                  inputProps={{ 'aria-label': 'your-account-name' }}
                  onChange={(e) => {
                    setSandboxAccountName(e.target.value.toLowerCase());
                    setIsValid(true);
                  }}
                  autoFocus
                  sx={
                    !isValid ? styles.errorInputBoxStyle : styles.inputBoxStyle
                  }
                />
                <Typography type="REGULAR_TABLE_CELL" sx={styles.sandboxsuffixStyle}>
                  {getSandboxSuffix()}
                </Typography>
              </Box>
              {!isValid && 
                <Box sx={styles.errorBox}>
                  <img src={warning} width="16px" height="16px"></img>
                  <Typography
                    type="INPUT_LABEL"
                    sx={{ color: COLORS.TEXT_DELETE }}
                  >
                    {LoginMessages.enterValidAccountName}
                  </Typography>
                </Box>
              }
            </Box>
          </Box>
          <Box sx={styles.goButtonStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }}
              disabled={!loading ? false : true}
            >
              {LoginMessages.goBack}
            </Button>
            <Box>
              <Button
                variant="contained"
                disabled={sandboxAccountName.length > 0 && !loading ? false : true}
                onClick={() => {
                  handleSandboxAccountRedirection();
                }}
                sx={styles.disabledButton}
              >
                {loading && 
                  <CircularProgress size={20} sx={styles.loadingCircle} />
                }
                {LoginMessages.continue}
              </Button>
            </Box>
          </Box>
          <Box sx={styles.divider} />
          <Box sx={styles.textLink}>
            <LinkWrapper href={externalLinks.dontKnowAccountName}>
              <Typography type="SMALL_SUB_HEADER">
                {LoginMessages.dontKnowAccountName}
              </Typography>
            </LinkWrapper>
          </Box>
        </Box>
      }

      {ActiveStep === 1 && selectedValue === globalMessages.Production && 
        <Box sx={styles.innerBox}>
          <Box sx={styles.logoBox}>
            <img src={logo} style={styles.imagestyle} />
          </Box>
          <Box sx={styles.headerBox}>
          <Typography variant="h4" sx={styles.title}>
              {LoginMessages.signInTitle}
            </Typography>
            <Typography type="MEDIUM_SUB_HEADER" sx={styles.mainHeader}>
              {LoginMessages.enterAccountName}
            </Typography>
            <Box>
              <Box sx={styles.inputLabel}>
                <Typography type="INPUT_LABEL">
                  {LoginMessages.accountName}
                </Typography>
                <Typography type="INPUT_LABEL" sx={styles.ashtrixStyle}>
                  {LoginMessages.required}
                </Typography>{' '}
              </Box>
              <Box sx={styles.inputBox}>
                <ProdInputBase
                  placeholder="your-account-name"
                  value={prodAccountName}
                  inputProps={{ 'aria-label': 'your-account-name' }}
                  onChange={(e) => {
                    setProdAccountName(e.target.value.toLowerCase());
                    setIsValid(true);
                  }}
                  autoFocus
                  sx={
                    !isValid ? styles.errorInputBoxStyle : styles.inputBoxStyle
                  }
                />
                <Typography type="REGULAR_TABLE_CELL" sx={styles.suffixStyle}>
                  {getProdSuffix()}
                </Typography>
              </Box>
              {!isValid && 
                <Box sx={styles.errorBox}>
                  <img src={warning} width="16px" height="16px"></img>
                  <Typography
                    type="INPUT_LABEL"
                    sx={{ color: COLORS.TEXT_DELETE }}
                  >
                    {LoginMessages.enterValidAccountName}
                  </Typography>
                </Box>
              }
            </Box>
          </Box>
          <Box sx={styles.goButtonStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }}
              disabled={!loading ? false : true}
            >
              {LoginMessages.goBack}
            </Button>
            <Box>
              <Button
                variant="contained"
                disabled={prodAccountName.length > 0 && !loading ? false : true}
                onClick={() => {
                  handleProductionAccountRedirection();
                }}
                sx={styles.disabledButton}
              >
                {loading && 
                  <CircularProgress size={20} sx={styles.loadingCircle} />
                }
                {LoginMessages.continue}
              </Button>
            </Box>
          </Box>
          <Box sx={styles.divider} />
          <Box sx={styles.textLink}>
            <LinkWrapper href={externalLinks.dontKnowAccountName}>
              <Typography type="SMALL_SUB_HEADER">
                {LoginMessages.dontKnowAccountName}
              </Typography>
            </LinkWrapper>
          </Box>
        </Box>
      }
    </Box>
  );
};
export default LoginForm;