import { GraphQLClient, gql } from 'graphql-request';

const sandboxEndpoint = process.env.GATSBY_SANDBOX_BEFFE_URL || '';

const endpoint = process.env.GATSBY_BEFFE_URL || '';

export const graphQLClient = new GraphQLClient(endpoint, {
  headers: {
    authorization: 'Bearer ',
  },
});

export const sandboxClient = new GraphQLClient(sandboxEndpoint, {
  headers: {
    authorization: 'Bearer ',
  },
});
export const ACCOUNT_DETAILS = gql`
  query AccountDetails($accountName: String!, $tenantName: String!) {
    OktaClientDetails(accountName: $tenantName) {
      groupId
      clientId
    }
    ValidateAccounts(request: { name: $accountName, tenantName: $tenantName }) {
      validPair
      account {
        id
        name
        tenantType
      }
      parentAccountId
      parentAccountName
    }
  }
`;
