import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '../components/common/Typography';
import LoginForm from '../components/common/LoginForm';
import LinkWrapper from '../components/common/Link';
import theme, { BREAKPOINTS, COLORS } from '../utils/theme';
import globalMessages from '../utils/messages/en/globalMessages';
import Background from '../../static/images/preLoginBackground.svg';
import {externalLinks} from '../utils/constants';
import routes from '../docs-config/src';
import LoadingState from '../components/common/LoadingState';
const styles = {
  footerTextStyle: {
    color: theme.palette.common.white,
    '&:hover': { backgroundColor: 'transparent' },
    [BREAKPOINTS.MEDIA_MAX_550]: {
      color: COLORS.PRIMARY_ACTIONS,
    },
  },
  buttonStyle: {
    padding: '0px',
    '&:hover': { backgroundColor: 'transparent' },
  },
  pageBackground: {
    backgroundImage: `url(${Background})`,
    maxHeight: '100%',
    minHeight: '99vh',
    [BREAKPOINTS.MEDIA_MAX_550]: {
      backgroundImage: 'none',
    },
  },
  modalStyle: {
    maxHeight: 'calc(100% - 40px)',
    minHeight: 'calc(99.8vh - 40px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [BREAKPOINTS.MEDIA_MAX_550]:{
      maxHeight:'100%',
      minHeight:'70%'
    }
  },
  footerBar: {
    [BREAKPOINTS.MEDIA_MAX_550]: {
      maxWidth: '158px',
      margin: 'auto',
    },
  },
  divider: {
    [BREAKPOINTS.MEDIA_MAX_550]: {
      border: '1px solid' + theme.palette.grey[300],
      maxWidth:'100%',
      margin: '0px auto',
    },
  },
  bottom: {
    [BREAKPOINTS.MEDIA_MAX_550]: {
      padding: '0px 32px',
      maxWidth:'100%',
    },
  },
  actionBar: {
    maxHeight: '40px',
    display: 'flex',
    justifyContent: 'center',
    gap: 11,
    padding: '10px 0px',
    backgroundColor: COLORS.BACKGROUND_ACTION_BAR,
    [BREAKPOINTS.MEDIA_MAX_550]: {
      backgroundColor: 'white',
      display: 'inline-grid',
      paddingTop: '34px',
      gap: 5,
      justifyItems: 'center',
    },
  },
  displayStyle: {
    display: 'none',
  },
};
const PreLogin = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  React.useEffect(() => {
    setIsLoading(false);
  }, []);
  let privateRoute=props.location?.state?.prevPath;
  if(!privateRoute){
    privateRoute=routes.home;
  }
  return (
    <>
    <Box sx={isLoading ? styles.displayStyle :styles.pageBackground}>
      <Box sx={styles.modalStyle}>
        <LoginForm route={privateRoute}/>
      </Box>
      <Box sx={styles.bottom}>
        <Box sx={styles.divider} />
        <Box sx={styles.footerBar}>
          <Box sx={styles.actionBar}>
            <LinkWrapper href={externalLinks.privacyPolicy}>
              <Button sx={styles.buttonStyle}>
                <Typography type="SMALL_SUB_HEADER" sx={styles.footerTextStyle}>
                  {globalMessages.privacyPolicy}
                </Typography>
              </Button>
            </LinkWrapper>
            <LinkWrapper href={externalLinks.termsAndConditions}>
              <Button sx={styles.buttonStyle}>
                <Typography type="SMALL_SUB_HEADER" sx={styles.footerTextStyle}>
                  {globalMessages.termsAndCondition}
                </Typography>
              </Button>
            </LinkWrapper>
          </Box>
        </Box>
      </Box>
    </Box>
    {isLoading && <LoadingState />}
    </>
  );
};
export default PreLogin;
